@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}
@mixin tab {
  @media screen and (max-width: 720px) {
    @content;
  }
}
@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

.about-project-page{
  width: 70%;
  margin: auto;
  word-spacing: 1px;
  // line-height: 1.8;
  letter-spacing: 0.1px;
  p{
    color: #c0c0c0;
  }
  @include mobile(){
    width: 90%;
    text-align: center;
  }
}

.all-projects {
  width: 80%;
  margin: auto;

  .title{
    font-size: large;
    margin: 2rem 0rem;
    font-weight: bold;
    border-bottom: 2px solid #00adb5;
    display: inline-block;
  }
  .project-container {
    width: 90%;
    width:fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    align-items: center;
    gap: 1rem;
  }

  .project {
    height: 12rem;
    width: 12rem;
    background-color: #00adb5;
    border-radius: 10%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;
    @include mobile() {
      height: 6rem;
      width: 8rem;
    }
  }

  .uiux-projects{
    animation-name: right;
    animation-duration: 1s;
  }
  
  .frontend-projects{
    animation-name: left;
    animation-duration: 1.2s;
  }
  .react-projects{
    animation-name: right;
    animation-duration: 1.5s;
  }
  .backend-projects{
    animation-name: left;
    animation-duration: 2s;
  }
  .mearn-projects{
    animation-name: right;
    animation-duration: 2.5s;
  }

  .project:hover {
    box-shadow: 0 15px 20px rgba(0, 173, 181, 0.4);
    transform: translateY(-5px);
  }

  .project::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(39, 39, 39, 0.541);
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .project:hover::before {
    transform: scaleX(1);
  }

  .description {
    position: absolute;
    bottom: 0%;
    p{
      font-size: small;
      font-weight: normal;
      bottom: 0px;
      margin: 0px;
      display: inline-block;
    }
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 16px;
    background-color: #00000060;
    font-weight: bold;
    font-size: large;
    color: lightgray;
    text-shadow: 2px 2px 6px  #000000;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease;
  }

  .project:hover .description {
    opacity: 1;
    transform: translateY(0);
  }

  .project img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
  .cool-button {
    background-color: #00adb5;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 50px;
    font-size: 18px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .cool-button:hover {
    background-color: #f8b195;
    color: #fff;
    box-shadow: 0 15px 20px rgba(0, 173, 181, 0.4);
    transform: translateY(-5px);
  }

  .cool-button::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .cool-button:hover::before {
    transform: scaleX(1);
  }
}
