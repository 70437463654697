.navbar {
  z-index: 10;
  background-color: rgba(29, 29, 29, 0.938);
  .nav_menu {
    a {
      padding-bottom: 4px;
      letter-spacing: 0.5px;
      &:hover {
        border-radius: 0%;
        background-color: rgba(0, 255, 255, 0);
        animation-name: navEffect;
        animation-duration: 1s;
      }
      &:active {
        background-color: rgb(0, 134, 134);
      }
    }

    

  }
  @keyframes navEffect {
    0% {
      border-bottom: 1px solid rgba(0, 0, 0, 0);
    }
    100% {
      border-bottom: 2px solid cyan;
    }
  }
  .nav-link {
    color: white;
  }
  .active {
    color: cyan;
    background-color: rgba(0, 0, 0, 0);
  }
  .link {
    text-decoration: none;
  }
  border-bottom: 0.01px solid rgba(211, 211, 211, 0.089)
}

.cool-button {
  background-color: #E76161;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.cool-button::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  background-color: #ED2B2A;
  transition: height 0.3s ease-in-out;
  z-index: -1;
}

.cool-button:hover::before {
  height: 100%;
}
