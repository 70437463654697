@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}
@mixin tab {
  @media screen and (max-width: 720px) {
    @content;
  }
}
@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}
.resume {
  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2%;
  }
  .image {
    width: 40%;
    margin-right: 10%;
    animation-name: left;
    animation-duration: 1s;
    @include mobile() {
      display: none;
    }
  }
  //----------------------------
  .timeline {
    width: 60%;
    margin-left: 10%;
    @include mobile() {
      width: 80%;
    }
    animation-name: right;
    animation-duration: 1s;
  }
  .icon-box {
    border-radius: 8px;
    box-shadow: -0.5px -0.5px 4px #00adb5;
  }
  .timeline .title-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 25px;
  }

  .timeline-list {
    margin-left: 45px;
  }

  .timeline-item {
    position: relative;
  }

  .timeline-item:not(:last-child) {
    margin-bottom: 20px;
  }

  .timeline-item-title {
    line-height: 1.3;
    margin-bottom: 7px;
  }

  .timeline-list span {
    color: #00adb5;
    font-weight: var(--fw-400);
    line-height: 1.6;
  }

  .timeline-item:not(:last-child)::before {
    content: "";
    position: absolute;
    top: -25px;
    left: -30px;
    width: 1px;
    height: calc(100% + 50px);
    background: #00acb56c;
  }

  .timeline-item::after {
    content: "";
    position: absolute;
    top: 5px;
    left: -33px;
    height: 6px;
    width: 6px;
    background: #00adb5;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #00acb536;
  }

  .timeline-text {
    color: white;
    font-weight: var(--fw-300);
    line-height: 1.6;
  }

  //------------------------------------skills

  .skills {
    margin: auto;
    // margin-top: 5px;
    padding: 0% 10%;
    width: 90%;
    display: flex;
    flex-direction: row;
    height:min-content;
    justify-content: center;
    flex-wrap: wrap;
    animation-name: top;
    animation-duration: 1s;
    .skill-name{
      display: inline;
      text-align: center;
      width: 100%;
      margin: 2rem;
    }
    img {
      border-radius: 20px;
      border: 1px solid #00adb5;
      margin: 1rem;
      padding: 0.5rem;
      height: 5rem;
      width: 5rem;
      box-shadow: 1px 0px 4px 1px #00adb5;
      cursor: pointer;

      &:hover {
        padding: 0.3rem;
        box-shadow: 2px 2px 8px 2px #00adb5;
      }
    }
    @include mobile() {
      img {
        height: 3rem;
        width: 3rem;
      }
    }
  }
  .Achievement {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2%;
  }
  .Certificates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2%;
    margin-bottom: 5%;
  }
  .bx-link-external{
    color: #00adb5;
    cursor: pointer;
  }
}
