@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}
@mixin tab {
  @media screen and (max-width: 720px) {
    @content;
  }
}
@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}
.hero {
  position: absolute;
  top: 0%;
  height: 70vh;
  padding: 0% 10%;
  .hero-content img {
    height: 20rem;
    width: 20rem;
    border-radius: 50%;
    border: 4px solid #00adb5;
    padding: 0.2rem;
    box-shadow: 2px 0px 8px #00adb5;
    animation-name: left;
    animation-duration: 1s;
  }
  .left-section {
    // background-color: aquamarine;
    .typed {
      color: #00adb5;
      animation-name: left;
      animation-duration: 0.75s;
    }
    .btns-group button {
      border: 1px solid #00adb5;
      animation-name: bottom;
      animation-duration: 1s;
      box-shadow: 1px 0px 8px #00adb5;
      &:hover {
        box-shadow: 2px 2px 18px 2px #00adb5;
      }
    }
    .details {
      animation-name: right;
      animation-duration: 0.75s;
    }
  }
  // .right-section{
  // background-color: lightcoral;
  // }
  @include mobile() {
    .left-section {
      margin: auto;
      text-align: center;
      .details {
        width: fit-content;
        text-align: center;
      }
    }
    .hero-content img {
      height: 10rem;
      width: 10rem;
    }
  }
}

//------------------------------------------

@keyframes left {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes right {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes top {
  0% {
    opacity: 0;
    transform: translateY(-150px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes bottom {
  0% {
    opacity: 0;
    transform: translateY(150px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
.custom-shape-divider-bottom-1678985483 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 10;
  transform: rotate(180deg);
  @include tab() {
    display: none;
  }
}

.custom-shape-divider-bottom-1678985483 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 127px;
}

.custom-shape-divider-bottom-1678985483 .shape-fill {
  // fill: rgba(163, 163, 163, 0.933);
  -webkit-animation: color-change-2x 2s linear infinite alternate both;
  animation: color-change-2x 2s linear infinite alternate both;
}


//---------------- color style
@-webkit-keyframes color-change-2x {
  0% {
    fill: #3f6f72;
  }
  100% {
    fill: #348c91;
  }
}
@keyframes color-change-2x {
  0% {
    fill: #2d888d;
  }
  100% {
    fill: #597779;
  }
}

@media screen and (max-width: 768px) {
  .custom-shape-divider-bottom-1678517062 {
    display: none;
  }
}
